<template>
  <div class="login-success">
    <sticker-with-text-component
      :title1="$t('loginsuccess.title1')"
      :title2="websocketFail ? $t('loginsuccess.titleError') : $t('loginsuccess.title2')"
    >
      <template #sticker>
        <img
          :src="websocketFail ? require('images/sdk-fail.png') : require('images/sdk-summer.png')"
        >
      </template>
      <template #text>
        {{
          websocketFail
            ? $t('loginsuccess.textError')
            : $t(
              `${
                websocketError
                  ? 'loginsuccess.text'
                  : 'loginsuccess.messageNoButton'
              }`,
            )
        }}
        <a
          class="simple-link"
          :href="`${supportURL}?source=account_login`"
        >
          {{ $t('loginsuccess.support') }}
        </a>
      </template>
    </sticker-with-text-component>
    <base-button
      v-if="websocketError"
      :is-type="['simple', 'vanish']"
      data-testid="open-voicemod-button"
      :is-disabled="buttonDisabled"
      @onClick="onCLickAction"
    >
      {{ $t('loginsuccess.button') }}
    </base-button>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { WebSocketClient } from '@/services/websocket-client';
import { AuthClient } from '@/services/models/authentication';
import { LOGIN_ACTIONS_CONSTANTS } from '@/domain/login';
import { ROUTER_PATHS } from '@/router/paths';
import { URL_CONSTANTS } from '@/externalURLs';

const REDIRECT_TO = {
    [LOGIN_ACTIONS_CONSTANTS.DOWNLOAD]: ROUTER_PATHS.ACCOUNT_ACTIVATED_SUCCESS,
    [LOGIN_ACTIONS_CONSTANTS.CHECKOUT]: ROUTER_PATHS.CHECKOUT,
};

export default {
    components: {
        StickerWithTextComponent: () =>
            import(
                /* webpackChunkName: "StickerWithTextComponent" */ '@/components/StickerWithTextComponent/StickerWithTextComponent'
            ),
        BaseButton: () =>
            import(/* webpackChunkName: "BaseButton"*/ '@RepoComponent/BaseButton/BaseButton'),
    },

    data() {
        return {
            buttonDisabled: false,
            code: this.$route.query.code,
            userId: this.$route.query.userId,
            isNew: this.$route.query.isNew,
            isNewLogin: localStorage.newLogin,
            hasRedirect: false,
            desktopCode: null,
            supportURL: URL_CONSTANTS.SUPPORT,
        };
    },

    computed: {
        ...mapState({
            websocketError: state => state.websocket_store.websocket.hasError,
            websocketFail: state => state.websocket_store.websocket.fail,
            websocketPorts: state => state.websocket_store.websocket.ports,
            userInfoStore: state => state.user_info_store.userInfo,
        }),
    },

    watch: {
        websocketError(newValue, oldValue) {
            if(newValue) {
                this.forceDeeplinkRedirect();
            }
        },
    },

    beforeMount() {
        const { payload } = this.$route.query;

        if (!payload) {
            this.openDesktopApp();
            return;
        }

        this.redirectTo(payload);
    },

    async mounted() {
        this.$store.dispatch('user_info_store/axn_setShowProfile', true);
    },

    methods: {
        onCLickAction() {
            this.buttonDisabled = true;
            this.forceDeeplinkRedirect();
        },

        forceDeeplinkRedirect() {
            window.location.href = `voicemod:login_token&code=${this.desktopCode}`;
        },

        redirectTo(payload) {
            const { redirect } = JSON.parse(payload);

            if (REDIRECT_TO[redirect]) {
                this.hasRedirect = true;
                this.$router.push({
                    path: REDIRECT_TO[redirect],
                    query: this.$route.query,
                });
                return;
            }

            this.openDesktopApp();
        },

        async openDesktopApp() {
            const { data: desktopData } = await AuthClient.generateNextgenCode(this.userId);
            const { ws:webSocketPort } = this.$route.query;

            if(webSocketPort) {
                this.$store.dispatch('websocket_store/axn_setWebsocketPorts', webSocketPort);
            }
            if (desktopData.code) {
                this.desktopCode = desktopData.code;
                this.$store.dispatch('websocket_store/axn_websocketUser', {
                    userID: this.userId,
                    isNew: this.isNew,
                    code: this.desktopCode,
                    email: this.userInfoStore.email,
                });

                try {
                    await WebSocketClient.connect({
                        server: 'login',
                        url: 'nlws',
                        actionType: 'loginDeepLink',
                    });
                } catch {
                    console.log('Failed to open websocket connection');
                }
            }
        },
    },
};
</script>

<style src="./FlagshipLoginSuccessView.scss" lang="scss"></style>
